import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const SecondSection = () => (
	<div id="second-section">
		<div className="heading">
			<h1>JAK TO DZIAŁA?</h1>
			<h2 className="text">
				Opiekun Auta powstał z zamiłowania i pasji do motoryzacji popartej
				wieloletnim doświadczeniem zdobytym w autoryzowanych serwisach i
				warsztatach mechaniki pojazdów. Nasze profesjonalne podejście,
				wiedza i umiejętności z dziedziny motoryzacji zdobywane przez lata
				pozwalają nam świadczyć usługi na najwyższym poziomie.
				<br /> <br /> Z nami nie musisz znać się na samochodach. Zostaw to
				nam! Przypilnujemy proces obsługi Twojego auta od pierwszego dnia!
				Działamy na terenie
				<span> całej WARSZAWY i jej okolic!</span>
			</h2>
		</div>
		<div className="content">
			<h1 className="head">
				PIERWSZE KROKI Z<br />
				<span className="subhead">OPIEKUNEM AUTA</span>
			</h1>
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="content-item pierwszy">
					<div className="icon" />
					<h3>
						<span className="number">1. </span> Dzwonisz, przyjeżdżamy do
						Ciebie uzgodnić szczegóły obsługi Twojego auta, które Cię
						interesują i chciałbyś je nam zlecić.
					</h3>
				</div>
			</ScrollAnimation>
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="content-item drugi">
					<div className="icon" />
					<h3>
						<span className="number">2. </span> Twoje auto przechodzi
						pierwsze oględziny, poznajemy jego historię, sprawdzamy stan
						techniczny.
					</h3>
				</div>
			</ScrollAnimation>
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="content-item trzeci">
					<div className="icon" />
					<h3>
						<span className="number">3. </span> Nie pobieramy żadnych
						początkowych opłat! Wszystko ustalamy indywidualnie przy
						dalszej współpracy.
					</h3>
				</div>
			</ScrollAnimation>
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="content-item czwarty">
					<div className="icon" />
					<h3>
						<span className="number">4. </span> Pilnujemy całego procesu
						obsługi Twojego auta, a naprawy zostawiamy zaufanym
						warsztatom.
					</h3>
				</div>
			</ScrollAnimation>
		</div>
	</div>
)

export default SecondSection
