import React from 'react'
import Menu from './menu'
import Particles from 'react-particles-js'

const HeroSection = () => (
	<div id="hero-section">
		<Particles
			style={{ width: '100%', position: 'absolute', zIndex: '9' }}
			params={{
				particles: {
					number: {
						value: 160,
						density: {
							enable: true,
							value_area: 1500
						}
					},
					line_linked: {
						enable: true,
						opacity: 0.02
					},
					move: {
						direction: 'right',
						speed: 0.05
					},
					size: {
						value: 1
					},
					opacity: {
						anim: {
							enable: true,
							speed: 5,
							opacity_min: 0.05
						}
					}
				},
				interactivity: {
					events: {
						onclick: {
							enable: true,
							mode: 'push'
						}
					},
					modes: {
						push: {
							particles_nb: 1
						}
					}
				},
				retina_detect: true
			}}
		/>
		<div className="brand" />
		<div className="make-darker" />
		<div className="divider" />
		<Menu />
	</div>
)

export default HeroSection
