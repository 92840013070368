import React, { Component } from 'react'
import 'animate.css/animate.min.css'
import './css/main.css'
import { Parallax } from 'react-parallax'
import image from './assets/firstPara.jpg'
import image2 from './assets/thirdsec.jpg'

import HeroSection from './components/hero-section'
import SecondSection from './components/second-section'
import ThirdSection from './components/third-section'
import ContactSection from './components/contact-section'
import Footer from './components/footer'
import Services from './components/services'

class App extends Component {
	render() {
		return (
			<div className="App">
				<HeroSection />
				<Parallax bgImage={image2} strength={0} className="secondParallax">
					<div style={{ height: '100%' }}>
						<Services />
					</div>
				</Parallax>

				<ThirdSection />

				<Parallax bgImage={image} strength={0} className="firstParallax">
					<div style={{ height: '100%' }}>
						<SecondSection />
					</div>
				</Parallax>

				<ContactSection />
				<Footer />
			</div>
		)
	}
}

export default App
