import React from 'react'
import { Link } from 'react-scroll'

const Footer = () => (
  <div id="footer">
    <div className="firm">
      <div className="logo" />
      <h1>Opiekun Auta</h1>
      <a href="tel:+48515263916">
        tel: <span>+48 515 263 916</span>
      </a>
      <br />
      <a href="mailto: opiekunauta@gmail.com">
        e-mail: <span>opiekunauta@gmail.com</span>
      </a>
    </div>
    <hr />
    <div className="sections">
      <Link to="hero-section" spy={true} smooth={true} duration={400}>
        <p>Home</p>
      </Link>
      <Link to="second-section" spy={true} smooth={true} duration={400}>
        <p>Jak to działa?</p>
      </Link>
      <Link to="third-section" spy={true} smooth={true} duration={400}>
        <p>Oferta</p>
      </Link>
      <Link to="contact-section" spy={true} smooth={true} duration={400}>
        <p>Kontakt</p>
      </Link>
    </div>
    <hr />
    <div className="sign">
      <a href="#">
        <p>Opiekun Auta &copy;2019</p>
      </a>
    </div>
  </div>
)

export default Footer
