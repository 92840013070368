import React from 'react'

const ThirdSection = () => (
	<div id="third-section">
		<div className="wrapper">
			<div className="heading">
				<h1>OFERTA</h1>
			</div>
			<div className="oferta" />
		</div>
	</div>
)

export default ThirdSection
