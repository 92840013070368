import React, { Component } from 'react'
import { Link } from 'react-scroll'

export default class Menu extends Component {
	state = {
		open: false
	}

	toggleMenu = () => {
		this.setState({
			open: !this.state.open
		})
	}

	render() {
		return (
			<div id="menu">
				<div
					className={this.state.open ? 'menu-btn close' : 'menu-btn'}
					onClick={this.toggleMenu}
				>
					<div className="btn-line" />
					<div className="btn-line" />
					<div className="btn-line" />
				</div>
				<div
					className={this.state.open ? 'backdrop show' : 'backdrop'}
					onClick={this.toggleMenu}
				/>
				<div className={this.state.open ? 'menu show' : 'menu'}>
					<ul className={this.state.open ? 'menu-nav show' : 'menu-nav'}>
						<li
							className={this.state.open ? 'nav-item show' : 'nav-item'}
						>
							<Link
								to="hero-section"
								className="nav-link"
								spy={true}
								smooth={true}
								duration={400}
							>
								Home
							</Link>
						</li>
						<li
							className={this.state.open ? 'nav-item show' : 'nav-item'}
						>
							<Link
								to="third-section"
								className="nav-link"
								spy={true}
								smooth={true}
								duration={400}
							>
								Oferta
							</Link>
						</li>
						<li
							className={this.state.open ? 'nav-item show' : 'nav-item'}
						>
							<Link
								to="second-section"
								className="nav-link"
								spy={true}
								smooth={true}
								duration={400}
							>
								Jak to działa?
							</Link>
						</li>
						<li
							className={this.state.open ? 'nav-item show' : 'nav-item'}
						>
							<Link
								to="contact-section"
								className="nav-link"
								spy={true}
								smooth={true}
								duration={400}
							>
								Kontakt
							</Link>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}
