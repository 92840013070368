import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const Services = () => (
	<div id="services" className="services">
		<div className="single-service serwis">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Wizyta w serwisie</h1>
				<p>
					Umówimy wizytę, pojedziemy za Ciebie, przypilnujemy całego
					procesu obsługi Twojego auta.
				</p>
			</ScrollAnimation>
		</div>

		<div className="single-service checklista">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Check lista OPIEKUNAUTA</h1>
				<p>
					Prowadzimy dla Ciebie pełną dokumentację obsługi Twojego
					samochodu.
				</p>
			</ScrollAnimation>
		</div>

		<div className="single-service sprawnosc">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Sprawność techniczna auta</h1>
				<p>
					Skontrolujemy stan zawieszenia, hamulców, oświetlenia, ogumienia,
					płynów eksploatacyjnych, elektroniki.
				</p>
			</ScrollAnimation>
		</div>

		<div className="single-service awaria">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Awaria w drodze</h1>
				<p>
					Pomożemy zdiagnozować usterki, zapewnimy auto zastępcze,
					zorganizujemy transport Twojego auta.
				</p>
			</ScrollAnimation>
		</div>

		<div className="single-service wymiana">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Sezonowa wymiana opon</h1>
				<p>
					Przyjedziemy po auto, wymienimy opony i odstawimy je w dogodne
					dla Ciebie miejce. Przechowamy Twoje opony do kolejnego sezonu!
				</p>
			</ScrollAnimation>
		</div>

		<div className="single-service indywidualne">
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
				<div className="icon" />
				<h1>Indywidualne podejście</h1>
				<p>Ekskluzywna oferta spersonalizowanych usług dla wymagających.</p>
			</ScrollAnimation>
		</div>
	</div>
)

export default Services
