import React, { Component } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import ScrollAnimation from 'react-animate-on-scroll'
import axios from 'axios'

export default class ContactSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        imie: '',
        email: '',
        message: ''
      },
      snackbar: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const { formData } = this.state
    formData[event.target.name] = event.target.value
    this.setState({ formData })
  }

  handleSubmit() {
    const { imie, email, message } = this.state.formData

    const emailData = {
      service_id: 'gmail',
      template_id: 'opiekunauta',
      user_id: 'user_iSt30g75q7KaG5iN1ofpZ',
      template_params: {
        imie,
        email,
        message
      }
    }

    axios
      .post(`https://api.emailjs.com/api/v1.0/email/send`, emailData)
      .then(res => {
        console.log(res)
      })

    setTimeout(() => this.setState({ snackbar: true }), 100)
    setTimeout(() => this.setState({ snackbar: false }), 3000)
  }

  render() {
    const { formData } = this.state
    return (
      <div id="contact-section" className="contact">
        <h1>KONTAKT</h1>
        <h2>
          Chcesz się dowiedzieć więcej o ofercie przygotowanej specjalnie dla
          Ciebie?
        </h2>
        <ScrollAnimation animateIn="bounceInRight" animateOnce={true}>
          <div className="telefon">
            <a href="tel:+48515263916">tel: +48 515 263 916</a>
          </div>
          <div className="mail">
            <a href="mailto: opiekunauta@gmail.com">
              e-mail: opiekunauta@gmail.com
            </a>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="bounceInLeft" animateOnce={true}>
          <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
            <div className="input-area-up">
              <TextValidator
                label="Twoje imię"
                className="name-input"
                onChange={this.handleChange}
                name="imie"
                value={formData.imie}
                validators={['required']}
                errorMessages={['To pole jest wymagane']}
              />
              <TextValidator
                label="Adres e-mail"
                type="email"
                className="email-input"
                onChange={this.handleChange}
                name="email"
                value={formData.email}
                validators={['required', 'isEmail']}
                errorMessages={[
                  'To pole jest wymagane',
                  'Podany adres e-mail nie jest poprawny'
                ]}
              />
            </div>
            <div className="input-area-down">
              <TextValidator
                label="Wpisz wiadomość"
                className="message-input"
                onChange={this.handleChange}
                name="message"
                value={formData.message}
                rows="5"
                multiline
                validators={['required']}
                errorMessages={['To pole jest wymagane']}
              />
            </div>
            <div className="input-button">
              <button className="btn-contact" type="submit">
                Wyślij wiadomość
              </button>
            </div>
          </ValidatorForm>
        </ScrollAnimation>
        <div
          className={
            this.state.snackbar ? 'snackbar snackbar-show' : 'snackbar'
          }
        >
          Wiadomość została wysłana!
        </div>
      </div>
    )
  }
}
